<template>
  <v-container class="pa-0" color="white">
    <div color="white">
      <base-subheading>{{ $t('MeeTeeMeeNgern1.CookiePolicyHeader') }}</base-subheading>

      <v-row>
        <v-col
          cols="12"
        >
          <p>สำหรับบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้อง</p>
<p>1. วัตถุประสงค์และขอบเขต

                บริษัท มีที่ มีเงิน จำกัด (“บริษัทฯ”) ยึดมั่นในการปฏิบัติหน้าที่ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (“พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล”) โดยประกาศความเป็นส่วนตัวฉบับนี้ช่วยให้ท่านเข้าใจแนวปฏิบัติของบริษัทฯ ในการคุ้มครองข้อมูลส่วนบุคคลของท่าน (ตามที่นิยามไว้ด้านล่างนี้) รวมทั้งอธิบายเหตุผลและวิธีการของบริษัทฯ ในการเก็บรวบรวม ใช้ เปิดเผย โอนไปยังต่างประเทศ และ/หรือ ดำเนินการด้วยวิธีอื่นใด (รวมเรียกว่า “ประมวลผล”) ซึ่งข้อมูลส่วนบุคคลของท่าน และอธิบายสิทธิที่ท่านมีเกี่ยวกับข้อมูลส่วนบุคคลของท่าน รวมถึงสิทธิในการปกป้องข้อมูลส่วนบุคคลดังกล่าว

                ประกาศความเป็นส่วนตัวฉบับนี้ใช้บังคับกับการประมวลผลข้อมูลส่วนบุคคลของบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้อง โดยบรรดาบุคคลที่เกี่ยวข้องทุกฝ่ายควรอ่านและทำความเข้าใจ รวมถึงทบทวนประกาศความเป็นส่วนตัวฉบับนี้อย่างสม่ำเสมอเพื่อที่จะได้มีความเข้าใจเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านอย่างถ่องแท้

ในประกาศความเป็นส่วนตัวฉบับนี้ “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลใด ๆ เกี่ยวกับบุคคลธรรมดาที่ยังมีชีวิตอยู่ ซึ่งสามารถทำให้ระบุตัวบุคคลนั้นได้ด้วยข้อมูลนั้นเอง หรือเมื่อนำข้อมูลนั้นไปรวมกับข้อมูลอื่นก็ตาม และ“ข้อมูลส่วนบุคคลที่อ่อนไหว” หมายถึง ข้อมูลส่วนบุคคลใด ๆ เกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ลัทธิ ความเชื่อในลัทธิศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม และข้อมูลชีวภาพของบุคคล

ประกาศความเป็นส่วนตัวฉบับนี้ใช้บังคับกับบุคคลธรรมดาซึ่งเป็นลูกค้าของบริษัทฯ ทั้งบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขี่ พยาน ผู้ค้ำประกัน คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา) ซึ่งในประกาศความเป็นส่วนตัวฉบับนี้จะเรียกโดยรวมว่า “ท่าน”

หากท่านมีคำถามหรือข้อเสนอแนะประการใดเกี่ยวกับแนวปฏิบัติของบริษัทฯ ในการคุ้มครองข้อมูลส่วนบุคคลของท่าน หรือหากท่านต้องการใช้สิทธิของท่านตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล ท่านสามารถติดต่อบริษัทฯ ผ่านช่องทางต่อไปนี้

บริษัท  มีที่ มีเงิน จำกัด

63/2 อาคารบริษัท ทิพยประกันภัย จำกัด(มหาชน) ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร เบอร์โทรศัพท์ 02-025-6999

เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล Email : MKT@meetee.co.th หมายเลขโทรศัพท์ 02-025-6999</p>

<p>2. บุคคลที่มีสิทธิในการประมวลผลข้อมูลส่วนบุคคลของท่าน

               เนื่องจากบริษัทฯ มีบทบาทและหน้าที่ในการกำหนดวัตถุประสงค์และวิธีการในการประมวลผลข้อมูลส่วนบุคคลของท่านให้เป็นไปตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล บริษัทฯ จึงทำหน้าที่เป็นผู้ควบคุมข้อมูลส่วนบุคคลของท่าน</p>

<p>3.ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวม

ข้อมูลส่วนบุคคลทั่วไป

กรณีของบุคคลที่อาจเป็นลูกค้า

บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของบุคคลที่อาจเป็นลูกค้า ซึ่งรวมถึงแต่ไม่จำกัดเพียงประเภทข้อมูลดังต่อไปนี้

ข้อมูลที่สามารถระบุตัวตนได้ เช่น ชื่อ นามสกุล ชื่อเล่น เลขบัตรประจำตัวประชาชน
ข้อมูลประวัติ เช่น เพศ
ข้อมูลการติดต่อ เช่น เบอร์โทรศัพท์ ที่อยู่ อีเมล Facebook LINE ID
ข้อมูลระบุทรัพย์สินของบุคคล เช่น ประเภททรัพย์สินที่มี ทะเบียนรถยนต์ ยี่ห้อรถยนต์ เลขที่โฉนด ความเป็นเจ้าของรถยนต์
ข้อมูลเกี่ยวกับการจ้างงาน เช่น อาชีพ รายได้ และ
ข้อมูลอื่น ๆ เช่น บัญชี Facebook วงเงินสินเชื่อที่สนใจ ผลิตภัณฑ์ที่สนใจ
กรณีของลูกค้าปัจจุบันและบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขื่ พยาน ผู้จัดการมรดก ผู้ค้ำประกัน ผู้กู้ร่วม คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา)

บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของลูกค้าปัจจุบันและบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขื่ พยาน ผู้จัดการมรดก ผู้ค้ำประกัน ผู้กู้ร่วม คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา) ซึ่งรวมถึงแต่ไม่จำกัดเพียงประเภทข้อมูลดังต่อไปนี้

ข้อมูลที่สามารถระบุตัวตนได้ เช่น ชื่อ นามสกุล ชื่อเล่น ลายมือชื่อ สำเนาทะเบียนบ้าน สำเนาบัตรประจำตัวประชาชน เลขบัตรประจำตัวประชาชน สำเนาใบอนุญาตขับขี่ ลายมือชื่อ รหัสลูกค้า รูปภาพ
ข้อมูลประวัติ เช่น เพศ สัญชาติ อายุ ความสัมพันธ์กับลูกค้า เพศ วันเกิด สถานภาพการสมรส จำนวนบุตร ส่วนสูง น้ำหนัก
ข้อมูลการติดต่อ เช่น ที่อยู่ เบอร์โทรศัพท์ อีเมล บัญชี LINE บัญชี Facebook แผนที่บ้าน
ข้อมูลการเงิน เช่น เลขที่บัญชีธนาคาร สำเนาบัญชีธนาคาร แหล่งที่มาของรายได้ จำนวนหนี้และอัตราดอกเบี้ย ยอดหนี้ค้างชำระ ยอดหนี้ครบกำหนดชำระ
ข้อมูลระบุทรัพย์สินของบุคคล เช่น รายละเอียดหลักประกัน วงเงิน สำเนาโฉนดที่ดิน สำเนาทะเบียนรถยนต์ รูปและรายละเอียดของหลักทรัพย์ กรรมสิทธิ์ในที่อยู่
ข้อมูลเกี่ยวกับการจ้างงาน เช่น อาชีพ ตำแหน่ง รายได้ หนังสือรับรองเงินเดือน อายุงาน สถานที่ทำงาน
ข้อมูลอื่น ๆ เช่น เลขที่สัญญา วันที่ทำสัญญา รายละเอียดการขอเอาประกันภัย บันทึกเสียง กรมธรรม์ประกันรถยนต์ รายละเอียดการชำระค่างวด เรื่องที่ต้องการร้องเรียน
 
ข้อมูลส่วนบุคคลที่อ่อนไหว

                นอกเหนือจากข้อมูลส่วนบุคคลที่ระบุไว้ข้างต้น บริษัทฯ อาจประมวลผลข้อมูลส่วนบุคคลที่อ่อนไหวของลูกค้าปัจจุบันและบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขื่ พยาน ผู้จัดการมรดก ผู้ค้ำประกัน ผู้กู้ร่วม คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา) ดังต่อไปนี้

ข้อมูลสุขภาพ
ข้อมูลความพิการ
ข้อมูลศาสนา
ข้อมูลเชื้อชาติ</p>
<p>4. บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร

โดยทั่วไปแล้ว บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขื่ พยาน ผู้จัดการมรดก ผู้ค้ำประกัน ผู้กู้ร่วม คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา) โดยตรง อย่างไรก็ตาม ในบางกรณี บริษัทฯ อาจเก็บรวบรวมข้อมูลส่วนบุคคลโดยอ้อมจากบุคคลที่สาม</p>

<p>5. บริษัทฯ นำข้อมูลส่วนบุคคลของท่านไปใช้อย่างไร

กรณีของบุคคลที่อาจเป็นลูกค้า

บริษัทฯ ใช้ข้อมูลส่วนบุคคลของบุคคลที่อาจเป็นลูกค้า เพื่อดำเนินการต่าง ๆ ตามขอบเขตและวัตถุประสงค์ในดำเนินกิจกรรมต่าง ๆ ซึ่งรวมถึงแต่ไม่จำกัดเพียง

การติดต่อ
การทำการตลาด
กรณีของลูกค้าปัจจุบันและบุคคลที่เกี่ยวข้อง (รวมถึง ผู้รับผลประโยชน์ ผู้ขับขื่ พยาน ผู้จัดการมรดก ผู้ค้ำประกัน ผู้กู้ร่วม คู่สมรสของลูกค้า/ผู้กู้ร่วม ผู้ชนะการประมูล ผู้รับมอบรถ บุคคลที่สามารถติดต่อสอบถามหรือทวงถามหนี้ได้ และบุคคลที่ติดต่อเข้ามา)

                บริษัทฯ ใช้ข้อมูลส่วนบุคคลของลูกค้าปัจจุบันและบุคคลที่เกี่ยวข้อง เพื่อดำเนินการต่าง ๆ ตามขอบเขตและวัตถุประสงค์ในดำเนินกิจกรรมต่าง ๆ ซึ่งรวมถึงแต่ไม่จำกัดเพียง

การทำการตลาด
การจัดกิจกรรมที่เกี่ยวข้องกับวัตถุประสงค์ทางการตลาด
การติดต่อ
การยืนยันตัวตน
การขายประกันภัยทางโทรศัพท์
การวิเคราะห์ความเสี่ยงในการให้สินเชื่อ
การพิจารณาอนุมัติสินเชื่อ
การติดตามทวงหนี้ และการสืบทรัพย์
การตรวจสอบยืนยันการปิดหนี้
การยึดหลักทรัพย์ และทำรายงาน
การประมูลหลักทรัพย์ที่ยึดมา
การดำเนินคดี
การดำเนินการที่เกี่ยวข้องกับการสมัครประกันภัย
การตรวจสอบการทำธุรกรรมรายการต้องสงสัยตามข้อกำหนดสำนักงานป้องกันและปราบปรามการฟอกเงิน
การตรวจสอบลงพื้นที่
การจดหลักประกันทางธุรกิจ
การเก็บข้อมูลในฐานข้อมูล
การรับเรื่องติดต่อผ่านช่องทาง Call Center
การรับเรื่องร้องเรียน
การตอบคำถามผ่านช่องทางออนไลน์
การคืนเงินเยียวยา
การจัดส่งสินค้า
การทำเอกสารทางการเงิน เช่น การออกใบเสร็จตรวจสอบเงินเกิน การทำบัญชีลูกหนี้ การทำบัญชีเจ้าหนี้ การออกใบกำกับภาษี การออกเอกสารรับชำระค่างวด
                บริษัทฯ อาศัยฐานทางกฎหมายและเงื่อนไขพิเศษบางประการดังต่อไปนี้ในการประมวลผลข้อมูลส่วนบุคคลของท่าน อนึ่ง ฐานทางกฎหมายและเงื่อนไขพิเศษบางประการที่ระบุด้านล่างนี้ มิได้ใช้กับการประมวลผลข้อมูลส่วนบุคคลของท่านทั้งหมด โดยการประมวลผลข้อมูลส่วนบุคคลอาจอาศัยฐานทางกฎหมายเพียงฐานใดฐานหนึ่งหรือหลายฐานรวมกันก็ได้

ฐานสัญญา
                บริษัทฯ มีหน้าที่ในการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติหน้าที่ตามสัญญากู้ โดยข้อมูลส่วนบุคคลที่ต้องใช้ในการประมวลผลตามฐานนี้ เป็นข้อมูลส่วนใหญ่ซึ่งปรากฏในข้อ 3. ในกรณีที่ท่านไม่ให้ข้อมูลส่วนบุคคลใดแก่บริษัทฯ เพื่อวัตถุประสงค์ที่กำหนดไว้ บริษัทฯ อาจไม่สามารถติดต่อหรือเข้าทำสัญญากับท่านได้

ฐานประโยชน์อันชอบธรรม
                เฉพาะในบางกรณี บริษัทฯ จำเป็นต้องใช้ข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์อันชอบธรรมของบริษัทฯ หรือของบุคคลที่สาม ทั้งนี้ ประโยชน์อันชอบธรรมดังกล่าวจะต้องไม่มีความสำคัญเหนือไปกว่าประโยชน์และสิทธิและเสรีภาพขั้นพื้นฐานของท่าน

ฐานความยินยอม
                ในบางกรณี บริษัทฯ จำเป็นต้องได้รับความยินยอมโดยชัดแจ้งจากบุบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้องในการประมวลผลข้อมูลส่วนบุคคลของบุคคลที่อาจเป็นลูกค้า ลูกค้าปัจจุบัน และบุคคลที่เกี่ยวข้องสำหรับการติดต่อรวมถึงการทำการตลาด

ฐานหน้าที่ตามกฎหมาย
                บริษัทฯ มีหน้าที่ในการประมวลผลข้อมูลส่วนบุคคลของท่านตามหน้าที่ตามกฎหมาย คำสั่งศาล หรือคำพิพากษาใด ๆ ที่เกี่ยวข้องกับการดำเนินการของบริษัทฯ ในกรณีที่ท่านไม่ให้ข้อมูลส่วนบุคคลใดแก่บริษัทฯ เพื่อวัตถุประสงค์ที่กำหนดไว้ อาจมีผลที่ตามมาตามกฎหมายที่เกี่ยวข้อง

การปฏิบัติตามกฎหมายเพื่อให้บรรลุวัตถุประสงค์เกี่ยวกับฐานประโยชน์สาธารณะที่สำคัญ
                บริษัทฯ มีความจำเป็นในการปฏิบัติตามกฎหมายเพื่อให้บรรลุวัตถุประสงค์เกี่ยวกับประโยชน์สาธารณะที่สำคัญสำหรับการเก็บข้อมูลสุขภาพของลูกค้าที่ต้องการสมัครประกันภัยวินาศหรือประกันภัยชีวิต</p>

<p>6. บริษัทฯ เปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านให้กับบุคคลใดบ้าง

บริษัทฯ อาจจำเป็นต้องเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกเพื่อประมวลข้อมูลส่วนบุคคลของท่าน โดยบุคคลดังกล่าวอาจรวมถึงบุคคลดังต่อไปนี้

บริษัทในเครือที่เกี่ยวข้อง
ผู้ให้บริการคลาวด์
Outsourced Agency ที่ทำการติดตามทวงหนี้
บริษัทประมูลรถยนต์
หน่วยงานของรัฐที่เกี่ยวข้อง
บริษัทประกัน
พนักงานภายนอก
บริษัทตรวจสอบบัญชี
บริษัทที่ทำการจัดเก็บเอกสาร
บริษัทขนส่ง</p>
<p>7. การโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</p>

เพื่อวัตถุประสงค์ที่ระบุไว้ในประกาศความเป็นส่วนตัวฉบับนี้ บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกหรือเซิร์ฟเวอร์ที่อยู่ในต่างประเทศซึ่งอาจมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลเช่นเดียวกับประเทศไทยหรือไม่ก็ได้ นอกจากนี้ บริษัทฯ ได้ดำเนินมาตรการเพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลของท่านถูกโอนไปอย่างปลอดภัยและผู้รับข้อมูลส่วนบุคคลมีการใช้มาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสม และการโอนข้อมูลส่วนบุคคลนั้นเป็นไปตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล</p>

<p>8. มาตรการรักษาความปลอดภัยเพื่อการคุ้มครองข้อมูลส่วนบุคคล</p>

<p>บริษัทฯ ได้จัดให้มีมาตรการเชิงเทคนิคและมาตรการเชิงบริหารจัดการเพื่อป้องกันการสูญหายของข้อมูลส่วนบุคคล การใช้ข้อมูลส่วนบุคคลในทางที่ผิด รวมทั้งการเข้าถึง การเปิดเผย และการเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านโดยไม่ได้รับอนุญาต และบุคคลภายนอ</p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Instagram',

    data: () => ({
      posts: [
        { src: 'adventurealtitude.jpg' },
        { src: 'garden.jpg' },
        { src: 'pigduck.jpg' },
        { src: 'rain.jpg' },
        { src: 'spices.jpg' },
        { src: 'sunset.jpg' },
      ],
    }),
    methods: {
      selectLanguage (language) {
        console.log(language, 'language componnet Cookie-policy')
      },
    }
  }
</script>
